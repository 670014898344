<template>

  <span>
    

<InputText v-if="!mostrarPanelBusquedas" v-model="terminoBusqueda" @focus="activarBusqueda" @input="debouncedBuscarProductos" placeholder="Buscar productos..." style="width: 100%;" />


  <div class="catalogo" v-else>
    <div class="search-header mt-2">
      <img src="@/assets/logo.png" alt="Logo" class="logo" >
      <InputText v-model="terminoBusqueda" @focus="activarBusqueda" @input="debouncedBuscarProductos" placeholder="Buscar productos..." style="width: 100%;" />
      <button @click="cerrarBusquedas" class="close-btn">X</button>

      <div class="busquedas-recientes-wrapper" v-if="busquedasRecientes.length">
        <div class="titulo-busquedas-recientes">Últimas búsquedas</div>
        <div class="busquedas-recientes">
          <div v-for="(busqueda, index) in busquedasRecientes" :key="busqueda" class="busqueda-item" @click="seleccionarBusqueda(busqueda)">
            {{ busqueda }}
            <span class="eliminar-busqueda" @click.stop="eliminarBusqueda(index)">X</span>
          </div>
        </div>
      </div>

    </div>

    <ProgressSpinner v-if="cargando" style="width: 50px; height: 50px; top: 200px; z-index: 1000; position: fixed;" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></ProgressSpinner>

    <div v-if="!productos.length && terminoBusqueda && !cargando">
      <p>No se encontraron resultados para "{{ terminoBusqueda }}".</p>
    </div>


     <div v-if="!productos.length && !terminoBusqueda && cargando">
      <p>Inicie su búsqueda.</p>
    </div>


       <div class="grid" v-if="productos.length">
 
      <div class="card" v-for="producto in productos" :key="producto.producto_consolidado_id" @click="abrirProductoWordPress(producto.slug)">


      <img :src="producto.img_url || '@/assets/woocommerce-placeholder-500x500.png'" 
     alt="Imagen de producto" 
     style="margin-top: 15px; padding: 15px;"
     @error="onImageError"
     @load="onImageLoad" />



        <div class="producto-info">
           <p class="bold-text text-white font-bold text-xs ml-2">
                    {{ formatPrice(producto.precio_wordpress) }}
                </p>
          <p>{{ producto.nombre }}</p>
          
        </div>
      </div>

    </div>

       <Button class="Buttonnn" v-if="productos.length && mostrarMas" label="+" @click="cargarMasProductos" style="position: fixed; bottom: 20px; right: 20px"/>
  </div>
    </span>
</template>


<script>

import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import ProductoService from '@/services/ProductoService';
import _ from 'lodash';

export default {
  computed:{imageClass() {
    if(this.producto)
      return this.producto.img_url ? 'product-image' : 'backup-logo';
    else return 'backup-logo';
    
  }},
  components: {
    InputText,
    Button
  },
  data() {
    return {
      terminoBusqueda: '',
      productos: [],
      paginaActual: 1,
      mostrarMas: false,
      busquedasRecientes: [],
      mostrarPanelBusquedas: false,
      cargando: false,
      ultimoTerminoBusquedaValido: '',
      temp_productos: [],
       backupImageUrl: require('@/assets/woocommerce-placeholder-500x500.png')
    };
  },
  created() {
    this.debouncedBuscarProductos = _.debounce(this.buscarProductos, 500);

    this.cargarBusquedasRecientes();
  },
  methods: {


 onImageError(event) {
    event.target.src = this.backupImageUrl;
  },


     formatPrice(value) {
        return Number(value).toLocaleString('es-CL', {
            style: 'currency',
            currency: 'CLP',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        }).replace('CLP', '').trim();
    },
    activarBusqueda() {
      if (this.busquedasRecientes.length > 0) {
        this.mostrarPanelBusquedas = true;
      }
    },
    seleccionarBusqueda(busqueda) {
      this.terminoBusqueda = busqueda;
      this.paginaActual = 1;
      this.buscarProductos();
    },


    abrirProductoWordPress(slug) {
      //this.productos = [];
       this.cargando = true;
  const urlBase = process.env.VUE_APP_URL_BASE_PRODUCTO;
  const urlProducto = `${urlBase}${slug}`;
  window.location.href = urlProducto; 
 
  setTimeout(() => {
    this.cargando = false;
  }, 3500);


},






async buscarProductos() {
  if (this.terminoBusqueda.length < 3) {
    this.productos = [];
    this.mostrarMas = false;
    this.cargando = false;
    return;
  }

  // Realizar la búsqueda solo si el término es más largo o diferente al último término válido
  if (this.terminoBusqueda.length > this.ultimoTerminoBusquedaValido.length || this.terminoBusqueda !== this.ultimoTerminoBusquedaValido) {
    this.cargando = true;
    const { productos, mostrarMas } = await ProductoService.buscarProductos(this.terminoBusqueda, this.paginaActual);

    this.productos = productos;
    this.temp_productos = productos;

    this.mostrarMas = mostrarMas;
    this.cargando = false;

    if (productos.length > 0) {
      this.guardarBusqueda(this.terminoBusqueda); // Solo guarda si hay resultados
      this.ultimoTerminoBusquedaValido = this.terminoBusqueda; // Actualizar el último término válido
      if (this.busquedasRecientes.length > 0) {
        this.mostrarPanelBusquedas = true;
      }
    } else {
      this.productos = [];
      this.mostrarMas = false;
    }
  }
},

 cerrarBusquedas() {
    this.mostrarPanelBusquedas = false; // Ocultar el panel de búsquedas recientes
  },



    async cargarMasProductos() {
      this.paginaActual++;
      const { productos, mostrarMas } = await ProductoService.buscarProductos(this.terminoBusqueda, this.paginaActual);
      this.productos.push(...productos);
      this.mostrarMas = mostrarMas;
    },
    eliminarBusqueda(index) {
      this.busquedasRecientes.splice(index, 1);
      localStorage.setItem('busquedasRecientes', JSON.stringify(this.busquedasRecientes));

    },

 guardarBusqueda(termino) {
  let busquedas = JSON.parse(localStorage.getItem('busquedasRecientes') || '[]');
  // Eliminar el término si ya existe para evitar duplicados
  busquedas = busquedas.filter(item => item !== termino);
  // Añadir el nuevo término al inicio
  busquedas.unshift(termino);
  // Mantener solo los últimos 3 términos únicos
  busquedas = busquedas.slice(0, 3);
  localStorage.setItem('busquedasRecientes', JSON.stringify(busquedas));
  this.busquedasRecientes = busquedas;
},

    cargarBusquedasRecientes() {
      this.busquedasRecientes = JSON.parse(localStorage.getItem('busquedasRecientes') || '[]');
      //this.mostrarPanelBusquedas = this.busquedasRecientes.length > 0;
    },
  }
};
</script>


<style type="text/css">

.product-image {
  margin-top: 15px;
  padding: 15px;

}

.backup-logo {
  margin-top: 20px; /* Ejemplo de diferente margen superior */
  padding: 10px; /* Ejemplo de diferente padding */
  opacity: 0.5; /* Podrías querer una opacidad diferente para el logo */
  animation: pulse 2s infinite ease-in-out; /* Si deseas el efecto palpitante aquí */
}
@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.75;
  }
  100% {
    transform: scale(1);
    opacity: 0.5;
  }
}
.container {
  display: flex;
  justify-content: center; /* Centra horizontalmente */
  align-items: center; /* Centra verticalmente */
  height: 100%; /* Asegúrate de que el contenedor tenga una altura */
}


.close-btn {
  z-index: 300;

    background-color: #fec525; /* Fondo amarillo */
    color: black;             /* Texto negro */
    border: none;             /* Sin borde */
    padding: 0.5em 1em;       /* Espaciado interno */
    font-weight: bold;        /* Texto en negrita */
    cursor: pointer;          /* Cursor de puntero para indicar que es clickeable */
    box-shadow: 2px 2px 5px rgba(0,0,0,0.2); /* Sombra ligera para efecto 3D */
    border-radius: 5px;       /* Bordes redondeados */
}

input[type="text"] { /* Asegúrate de que este selector apunte correctamente al InputText en tu caso */
    width: calc(100% - 70px); /* Ajusta el ancho para dar espacio al botón */
    margin-right: 10px; /* Espacio adicional si es necesario */
}

.bold-text {
    font-weight: bold;
}
.catalogo {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px; /* Asegurar que se extiende completamente a la derecha */
  bottom: 0px; /* Extender hasta el final de la ventana */
  overflow-y: auto; /* Activar scroll */
  padding: 20px; /* Añadir padding */
  background-color: white;
}

.catalogo .grid {
  position: relative;
  top: 90px; /* Ajustar según el espacio necesario para elementos anteriores */
  left: 0;
  right: 0;
  bottom: 70px; /* Espacio para el botón 'Mostrar más' */
  overflow-y: auto; /* Activar scroll */
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
}

/* Media query para dispositivos móviles */
@media (max-width: 600px) {
  .catalogo .grid {
    top: 190px; /* Mayor espacio en la parte superior para móviles */
  }
}



.catalogo .card {
  background: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;
}
.catalogo .card img {
  height: 150px;
  object-fit: cover;
}
.catalogo .producto-info {
  padding: 1rem;
}
@media (max-width: 600px) {
  .catalogo .grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Estilos base para el contenedor de búsquedas recientes */
.busquedas-recientes-wrapper {
  display: flex;  /* Flex container para alinear horizontalmente */
  flex-direction: row; /* Elementos en línea horizontal por defecto */
  align-items: center; /* Alinea los ítems verticalmente en el centro */
  position: absolute;
  left: 0;
  top: 50px; /* Ajusta según necesites espacio para otros elementos anteriores */
  width: 100%;
  padding: 10px; /* Espacio interior para todo el contenedor */
  background: white;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  z-index: 1000;
}

/* Estilos para el título */
.titulo-busquedas-recientes {
  font-size: 0.9rem;
  padding-right: 20px; /* Espacio a la derecha del título */
  white-space: nowrap; /* Evita que el título se envuelva en múltiples líneas */
}

/* Estilos para el contenedor de los ítems de búsqueda */
.busquedas-recientes {
  display: flex;
  flex-grow: 1; /* Toma el espacio restante */
  overflow-x: auto; /* Permite desplazamiento horizontal si hay muchos ítems */
  align-items: left; /* Alinea los ítems de búsqueda verticalmente */
}

/* Estilos para cada ítem de búsqueda */
.busquedas-recientes div.busqueda-item {
  padding: 5px 10px;
  margin-right: 10px; /* Espacio entre elementos */
  background: white; /* Fondo para cada item */
}

/* Media query para dispositivos móviles */
@media (max-width: 600px) {
  .busquedas-recientes-wrapper {
    flex-direction: column; /* Cambia la disposición a vertical */
    padding: 10px 0; /* Ajusta el padding para la vista vertical */
  }

  .titulo-busquedas-recientes {
    padding-bottom: 10px; /* Espacio debajo del título en vista móvil */
    padding-right: 0; /* Elimina el espacio derecho en vista móvil */
  }

  .busquedas-recientes {
    flex-direction: column; /* Alinea los ítems de búsqueda verticalmente */
    overflow-x: hidden; /* Deshabilita el desplazamiento horizontal */
  }

  .busquedas-recientes div.busqueda-item {
    margin-right: 0; /* Elimina el margen derecho en la disposición vertical */
  }
}



.search-header {
  display: flex;
  align-items: center;
  position: relative;
}



.logo {
  margin-right: 10px;
  height: 40px; /* Ajusta según el tamaño de tu logo */
}


/* Estilos base para el botón '+' */
.Buttonnn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  font-size: 24px; /* Tamaño más grande de texto */
  width: 60px; /* Tamaño base más grande */
  height: 60px; /* Tamaño base más grande */
  line-height: 60px; /* Alineación vertical del texto */
  background-color: #007BFF; /* Color de fondo azul */
  color: white; /* Texto blanco */
  border-radius: 50%; /* Borde redondo */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Sombra para mayor visibilidad */
  cursor: pointer;
  border: none; /* Eliminar borde por defecto */
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 600px) {
  .Buttonnn {
    font-size: 30px; /* Tamaño del texto más pequeño */
    width: 70px; /* Tamaño más compacto */
    height: 70px; /* Tamaño más compacto */
    line-height: 70px; /* Alineación vertical del texto ajustada */
  }
}

/* Estilos para dispositivos de escritorio */
@media (min-width: 601px) {
  .Buttonnn {
    font-size: 30px; /* Tamaño del texto más grande */
    width: 100px; /* Tamaño más expansivo */
    height: 100px; /* Tamaño más expansivo */
    line-height: 100px; /* Alineación vertical del texto ajustada */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.4); /* Sombra más prominente */
  }
}

</style>
