<template>
  <div class="home">
    <BuscarProducto/>
  </div>
</template>

<script>

import BuscarProducto from '@/components/BuscarProducto.vue'

export default {
  name: 'HomeView',
  components: {
    BuscarProducto
  }
}
</script>
