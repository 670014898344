import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL;

const buscarProductos = async (nombre, pagina) => {
  if (nombre.length < 3) {
    return { productos: [], mostrarMas: false };
  }

  const response = await axios.post(`${API_URL}buscar-productos/`, {
    nombre: nombre,
    pagina: pagina
  });

  return {
    productos: response.data.results,
    mostrarMas: response.data.total_pages > pagina
  };
};

export default {
  buscarProductos
};
