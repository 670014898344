import { createApp } from 'vue';
import App from './App.vue';
import router from './router'; // Asegúrate de tener un archivo router.js o una carpeta router con un archivo index.js
import PrimeVue from 'primevue/config';
import ProgressSpinner from 'primevue/progressspinner';

import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';

const app = createApp(App);
app.use(PrimeVue);
app.use(router); // Usa Vue Router
app.component('ProgressSpinner', ProgressSpinner);
app.mount('#app');